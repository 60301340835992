var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "pb-8" },
        [
          _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-shield-key")]),
          _vm._v(" Edição de menu "),
        ],
        1
      ),
      _c("v-card-subtitle", { staticClass: "headerClass" }, [
        _vm._v("Para desabilitar um menu basta deletar o objeto desejado"),
      ]),
      _c(
        "v-card-text",
        [
          _c("v-textarea", {
            attrs: { rows: "20", cols: "80" },
            model: {
              value: _vm.telas,
              callback: function ($$v) {
                _vm.telas = $$v
              },
              expression: "telas",
            },
          }),
          _vm._l(_vm.newAdmMenus, function (item) {
            return _c("v-switch", {
              key: item.name,
              staticClass: "switch-tfa",
              attrs: { id: item.name, "hide-details": "" },
              on: { change: _vm.setAlteredAdm },
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("span", [_vm._v(" " + _vm._s(item.name) + " ")]),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "prepend",
                    fn: function () {
                      return [_c("span", { attrs: { id: item } })]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
              model: {
                value: item.value,
                callback: function ($$v) {
                  _vm.$set(item, "value", $$v)
                },
                expression: "item.value",
              },
            })
          }),
        ],
        2
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "success",
                loading: _vm.loadingSave,
                disabled: _vm.loadingSave,
              },
              on: {
                click: function ($event) {
                  return _vm.resetScreensIn()
                },
              },
            },
            [_vm._v("Restaurar")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                color: "success",
                loading: _vm.loadingSave,
                disabled: _vm.loadingSave,
              },
              on: {
                click: function ($event) {
                  return _vm.save()
                },
              },
            },
            [_vm._v("Salvar")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }