<template>
  <v-card>
    <v-card-title class="pb-8">
      <v-icon left>mdi-shield-key</v-icon>
      Edição de menu
    </v-card-title>
    <v-card-subtitle class="headerClass">Para desabilitar um menu basta deletar o objeto desejado</v-card-subtitle>
    <v-card-text>
      <v-textarea rows="20" cols="80" v-model="telas"> </v-textarea>
      <!-- <v-textarea rows="20" cols="80" v-model="newAdmMenusSet"> </v-textarea> -->
      <v-switch
        v-for="item in newAdmMenus"
        :id="item.name"
        :key="item.name"
        v-model="item.value"
        hide-details
        class="switch-tfa"
        @change="setAlteredAdm"
      >
        <template v-slot:label>
          <span>
            {{ item.name }}
          </span>
        </template>
        <template v-slot:prepend><span :id="item"></span> </template>
      </v-switch>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="success" :loading="loadingSave" :disabled="loadingSave" @click="resetScreensIn()">Restaurar</v-btn>
      <v-btn color="success" :loading="loadingSave" :disabled="loadingSave" @click="save()">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { eventBus } from '../../../../main.js';
import defaultScreen from '@/assets/json/userDefault.json';

export default {
  props: ['id'],

  data() {
    return {
      newMenus: {},

      headers: [{ text: 'Telas', align: 'start', value: 'title' }],
      adminMenusDefault: [
        { name: 'adminMenu', value: true },
        { name: 'helpDesk', value: true },
        { name: 'profileScreen', value: true },
      ],
      newAdmMenus: [],
      menus: defaultScreen,

      loadingSave: false,
    };
  },

  computed: {
    ...mapState('cadastrosGerenciais/usuario', {
      user: (state) => state.user,
      roles: (state) => state.roles,
      loading: (state) => state.loading,
    }),
    ...mapGetters({
      menusUser: 'cadastrosGerenciais/usuario/getMenus',
      status: 'cadastrosGerenciais/usuario/getstatus',
      alteredMenu: 'cadastrosGerenciais/usuario/getAlteredMenu',
      alteredAdm: 'cadastrosGerenciais/usuario/getAlteredAdm',
      administrationMenus: 'cadastrosGerenciais/usuario/getAdminMenus',
    }),
    telas: {
      get() {
        return JSON.stringify(this.menusUser, null, 2);
      },
      set(telas) {
        this.setMenus(JSON.stringify(JSON.parse(telas)));
      },
    },

    ...mapGetters({ usuario: 'user/userInfo' }),
  },

  watch: {
    loading: {
      handler() {
        this.init();
      },

      deep: true,
      immediate: true,
    },
  },
  created() {
    this.init();
  },

  methods: {
    ...mapActions('cadastrosGerenciais/usuario', [
      'setAlteredAdm',
      'saveRoles',
      'saveRolesForAuth',
      'setMenus',
      'setAdminMenus',
      'resetScreens',
      'saveNewMenus',
      'saveSameMenus',
    ]),

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },

    init() {
      this.newAdmMenus = this.administrationMenus;
    },
    resetScreensIn() {
      let userAlterId = this.user.id;
      let userLayoutMenu = {
        layoutMenu: JSON.stringify(this.menus),
        adminMenus: JSON.stringify(this.adminMenusDefault),
      };
      let payload = { id: userAlterId, menus: userLayoutMenu };
      this.resetScreens(payload);
      this.loadingSave = true;
      if (this.status === 200) {
        this.showToast('Salvo com sucesso!', 'success', 78);
        this.loadingSave = false;
      } else {
        this.showToast('Não foi possivel salvar!', 'error', 78);
      }
    },

    async save() {
      let userAlterId = this.user.id;

      if (this.alteredMenu === true || this.alteredAdm === true) {
        await this.saveNewMenus(userAlterId);
      } else if (this.alteredOrNot === false) {
        let userAlterId = this.user.id;
        let userLayoutMenu = {
          layoutMenu: JSON.stringify(this.menus),
          adminMenus: JSON.stringify(this.administrationMenus),
        };
        let payload = { id: userAlterId, menus: userLayoutMenu };
        await this.saveSameMenus(payload);
      }
      this.loadingSave = true;
      if (this.status === 200) {
        this.showToast('Salvo com sucesso!', 'success', 78);
        this.loadingSave = false;
      } else {
        this.showToast('Não foi possivel salvar!', 'error', 78);
      }
    },
  },
};
</script>
<style scoped>
.switch-tfa >>> .v-input--switch__thumb.primary--text {
  color: white !important;
}

.switch-tfa >>> .v-input--switch__track {
  opacity: 100 !important;
}
</style>
